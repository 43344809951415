import { useEffect, useState } from 'react'
import { adaloBackendAxios } from 'utils/io/http/axios'

import Page from 'components/Shared/Page'
import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'

import './Admin.scss'
import {
  backupDatasource,
  hibernateDatasource,
  wakeUpDatasourceAsAdaloAdmin,
} from '../../utils/io'
import { HIBERNATION_STATUS } from '../../ducks/datasources'

const Hibernation = ({ id, status, changeLastBackupCreatedAt }) => {
  const [isLoading, setIsLoading] = useState(false)

  if (!id) {
    return <Loading />
  }

  const isReadyToWakeUp = status === HIBERNATION_STATUS.HIBERNATED
  const isReadyToHibernate = !status

  const onHibernateClick = async () => {
    if (window.confirm('Are you sure you want to hibernate this datasource?')) {
      setIsLoading(true)
      try {
        await hibernateDatasource(id)

        window.location.reload()
      } catch (error) {
        console.error('Error during hibernation:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const onWakeUpClick = async () => {
    if (window.confirm('Are you sure you want to wake up this datasource?')) {
      setIsLoading(true)
      try {
        await wakeUpDatasourceAsAdaloAdmin(id)

        window.location.reload()
      } catch (error) {
        console.error('Error during wake up:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const onBackupClick = async () => {
    if (window.confirm('Are you sure you want to backup this datasource?')) {
      setIsLoading(true)
      try {
        const result = await backupDatasource(id)
        changeLastBackupCreatedAt(result?.lastDumpVersion[0]?.createdAt)
      } catch (error) {
        console.error('Error during backup:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const renderStatusMessage = () => {
    switch (status) {
      case HIBERNATION_STATUS.HIBERNATING:
        return 'Datasource is currently hibernating... (refresh the page in a while)'
      case HIBERNATION_STATUS.HIBERNATED:
        return 'Datasource is hibernated.'
      case HIBERNATION_STATUS.WAITING_HIBERNATE:
        return 'Waiting to hibernate... (refresh the page in a while)'
      case HIBERNATION_STATUS.WAITING_AWAKE:
        return 'Waiting to wake up... (refresh the page in a while)'
      case HIBERNATION_STATUS.WAKING_UP:
        return 'Datasource is waking up... (refresh the page in a while)'
      case HIBERNATION_STATUS.FAILED_HIBERNATION:
        return 'Failed to hibernate datasource. Please retry.'
      case HIBERNATION_STATUS.WAKEUP_FAILED:
        return 'Failed to wake up datasource. Please check logs.'
      default:
        return `Unknown status: "${status}"`
    }
  }

  return (
    <div
      style={{
        paddingBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <p>{status && renderStatusMessage()}</p>
      <div style={{ display: 'flex', gap: 5 }}>
        <a
          onClick={onHibernateClick}
          style={{
            pointerEvents: isLoading || !isReadyToHibernate ? 'none' : 'auto',
            opacity: isLoading || !isReadyToHibernate ? 0.5 : 1,
          }}
        >
          Hibernate
        </a>
        |
        <a
          onClick={onWakeUpClick}
          style={{
            pointerEvents: isLoading || !isReadyToWakeUp ? 'none' : 'auto',
            opacity: isLoading || !isReadyToWakeUp ? 0.5 : 1,
          }}
        >
          Wake Up
        </a>
        |
        <a
          onClick={onBackupClick}
          style={{
            pointerEvents: isLoading || !isReadyToHibernate ? 'none' : 'auto',
            opacity: isLoading || !isReadyToHibernate ? 0.5 : 1,
          }}
        >
          Create Backup
        </a>
      </div>
    </div>
  )
}

const Datasource = ({ match }) => {
  const [hibernation, setHibernation] = useState(null)
  const [datasourceValidity, setDatasourceValidity] = useState(null)
  const [apps, setApps] = useState(null)
  const [host, setHost] = useState(null)
  const [metrics, setMetrics] = useState(null)
  const [tableNames, setTableNames] = useState(null)
  const [loadingError, setLoadingError] = useState(null)
  const [lastBackupCreatedAt, setLastBackupCreatedAt] = useState(null)

  const isDatasourceAccessible = hibernation && !hibernation?.status

  useEffect(() => {
    adaloBackendAxios
      .get(`/datasources/${match.params.datasourceId}/hibernation`)
      .then(h => setHibernation(h.data))
      .catch(err => setLoadingError(err.message))
    adaloBackendAxios
      .get(`/datasources/${match.params.datasourceId}/backup/details`)
      .then(b => {
        if (b.data?.lastDumpVersion) {
          setLastBackupCreatedAt(b.data?.lastDumpVersion[0].createdAt)
        }
      })
      .catch(err => setLoadingError(err.message))
    Promise.all([
      adaloBackendAxios.get(`/datasources/${match.params.datasourceId}/apps`),
      adaloBackendAxios.get(`/datasources/${match.params.datasourceId}`),
    ])
      .then(([apps, datasource]) => {
        setApps(apps.data)
        setTableNames(datasource.data)
      })
      .catch(err => setLoadingError(err.message))
  }, [match.params.datasourceId])

  useEffect(() => {
    if (isDatasourceAccessible) {
      Promise.all([
        adaloBackendAxios.get(
          `/datasources/${match.params.datasourceId}/validity`
        ),
        adaloBackendAxios.get(
          `/datasources/${match.params.datasourceId}/metrics`
        ),
        adaloBackendAxios.get(`/datasources/${match.params.datasourceId}/host`),
      ])
        .then(([responseValidity, metrics, host]) => {
          setDatasourceValidity(responseValidity.data)
          setMetrics(metrics.data)
          setHost(host.data.host)
        })
        .catch(err => setLoadingError(err.message))
    }
  }, [isDatasourceAccessible])

  const baseReady = hibernation && apps && tableNames
  const extendedReady = isDatasourceAccessible
    ? datasourceValidity && metrics && host
    : true
  const prerequisitesReady = baseReady && extendedReady

  if (!prerequisitesReady && loadingError) {
    return (
      <Page
        className="admin-users-page"
        title={`Datasource: ${match.params.datasourceId}`}
      >
        <h1>Datasource: {match.params.datasourceId}</h1>
        <Hibernation
          {...hibernation}
          changeLastBackupCreatedAt={setLastBackupCreatedAt}
        />
        <p>Last Backup Created At: {lastBackupCreatedAt || 'n/a'}</p>
        <hr />
        <p style={{ color: 'red' }}>
          Error retrieving Datasource ID={match.params.datasourceId}:{' '}
          {loadingError}
        </p>
      </Page>
    )
  }

  if (!prerequisitesReady) {
    return (
      <EmptyState greedy>
        <Loading large />
      </EmptyState>
    )
  }

  return (
    <Page
      className="admin-debug-page"
      title={`Datasource: ${match.params.datasourceId}`}
    >
      <h1>Datasource</h1>
      <Hibernation
        {...hibernation}
        changeLastBackupCreatedAt={setLastBackupCreatedAt}
      />
      <p style={{ marginBottom: '5px' }}>
        Last Backup Created At: {lastBackupCreatedAt || 'n/a'}
      </p>
      <div>
        <h2>Basic info</h2>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Datasource ID</th>
              <th>Host</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{match.params.datasourceId}</td>
              <td>{isDatasourceAccessible ? host : 'n/a'}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>Apps using datasource</h2>
        <table className="styled-table">
          <thead>
            <tr>
              <th>App ID</th>
              <th>Name</th>
              <th>Datasource Created</th>
              <th>Datasource Updated</th>
              <th>Datasource Deleted</th>
            </tr>
          </thead>
          <tbody>
            {apps.map(({ id, name, createdAt, updatedAt, deletedAt }) => (
              <tr key={id}>
                <td>
                  <a href={`/apps/${id}/data`} target="blank">
                    {id}
                  </a>
                </td>
                <td>{name}</td>
                <td>{createdAt}</td>
                <td>{updatedAt}</td>
                <td>{deletedAt ?? 'n/a'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <h2>Table information</h2>
        {isDatasourceAccessible ? (
          <table className="styled-table">
            <thead>
              <tr>
                <th>Table ID</th>
                <th>Name</th>
                <th>Records</th>
                <th>Errors</th>
              </tr>
            </thead>
            <tbody>
              {datasourceValidity.map(({ tableId, errors }) => (
                <tr key={tableId}>
                  <td>{tableId}</td>
                  <td>{tableNames[tableId]}</td>
                  <td>{metrics[tableId]}</td>
                  <td>
                    {errors.length !== 0 && (
                      <ul>
                        {errors.map(error => (
                          <li key={error}>{error}</li>
                        ))}
                      </ul>
                    )}
                    {errors.length === 0 && 'No errors'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Not available</p>
        )}
      </div>
    </Page>
  )
}

export default Datasource
