import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

// components
import Icon, { IconButton } from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import BetaLabel from 'components/AppDetail/BetaLabel'
import Tooltip from 'components/Shared/Tooltip'

// ducks
import { getApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users/index.ts'
import {
  createTable,
  getDatasources,
  removeTable,
  updateTable,
} from 'ducks/apps/datasources'
import { loadMetrics, getMetrics } from 'ducks/metrics'

// utils
import { verifyXanoApp } from 'utils/externalDatabases'
import useDbAssistantMenuOptions from './useDbAssistantMenuOptions'

// dependent components
import Items from './Items'

// custom styles
import './Panel.scss'

const DataPanel = props => {
  const {
    createTable,
    datasources,
    loadMetrics,
    history,
    metrics,
    removeTable,
    updateTable,
    appId,
    app,
    isAdmin,
  } = props

  const isXanoApp = verifyXanoApp(app)

  const datasource = datasources.length === 1 && datasources[0]
  const datasourceId = datasource && datasource.id

  const isDatabaseExists =
    datasources.length > 0 && Boolean(datasources[0]?.isDatabaseExists)
  const {
    handleMagicStartClicked,
    handleMagicAddClicked,
    ...dbAssistantOptions
  } = useDbAssistantMenuOptions(appId)

  const { appSupportsMagicStart, magicStartIsAvailable } = dbAssistantOptions

  useEffect(() => {
    if (isDatabaseExists) {
      loadMetrics(appId, datasourceId)
    }
  }, [datasourceId])

  const handleCollectionPermissionsClicked = () => {
    return history.push(`/apps/${appId}/data/${datasourceId}/permissions`)
  }

  const handleDatasourceDebugClicked = () => {
    if (!isAdmin) return

    return window.open(`/admin/datasource/${datasourceId}`, '_blank')
  }

  return (
    <div className="app-data-panel">
      <h2 className="left-panel-title">
        <Icon type="database-collections" />
        <span className="title">Database Collections</span>
        {!isXanoApp && (
          <CollectionsMenu
            isAdmin={isAdmin}
            onDatasourceDebugClick={handleDatasourceDebugClicked}
            onPermissionsClick={handleCollectionPermissionsClicked}
            onMagicStartClick={handleMagicStartClicked}
            onMagicAddClick={handleMagicAddClicked}
            {...dbAssistantOptions}
          />
        )}
      </h2>

      <Items
        appId={appId}
        datasources={datasources}
        datasourceId={datasourceId}
        metrics={metrics}
        createTable={createTable}
        removeTable={removeTable}
        updateTable={updateTable}
      />

      {appSupportsMagicStart && magicStartIsAvailable ? (
        <div className="magic-start-call-to-action">
          <BetaLabel />
          <h3>Magic Start Your Database</h3>
          <p>
            Not sure how to create a database? Just type your app idea and get a
            list of suggested collections!
          </p>
          <Button orange gradient onClick={handleMagicStartClicked}>
            Get Started
          </Button>

          <span>
            Powered By AI
            <Icon type="adalo-sparkles" small />
          </span>
        </div>
      ) : null}
    </div>
  )
}

const CollectionsMenu = ({
  onPermissionsClick,
  onMagicStartClick,
  appSupportsMagicStart,
  magicStartIsAvailable,
  hasRemainingMagicStartTries,
  onMagicAddClick,
  appSupportsMagicAdd,
  magicAddIsAvailable,
  hasRemainingMagicAddTries,
  isAdmin,
  onDatasourceDebugClick,
}) => {
  const options = [
    {
      label: (
        <div className="collections-menu-option">
          <Icon type="security-badge" /> <p>Permissions</p>
        </div>
      ),
      onClick: onPermissionsClick,
    },
  ]

  if (appSupportsMagicStart && magicStartIsAvailable) {
    let magicStartTooltip

    if (!hasRemainingMagicStartTries) {
      magicStartTooltip = 'You’ve used all your Generations this month.'
    } else if (!magicStartIsAvailable) {
      magicStartTooltip =
        'Magic Start isn’t currently available when your database has been modified.'
    }

    const disableOption = !hasRemainingMagicStartTries || !magicStartIsAvailable

    const magicStartMenu = (
      <div className="collections-menu-option">
        <Icon type="data" /> <p>Magic Start</p>
      </div>
    )

    const magicStartMenuWrapped = disableOption ? (
      <Tooltip
        className="magic-start-tooltip"
        tooltip={magicStartTooltip}
        placement="right"
      >
        {magicStartMenu}
      </Tooltip>
    ) : (
      magicStartMenu
    )

    options.unshift({
      label: magicStartMenuWrapped,
      onClick: onMagicStartClick,
      locked: disableOption,
      hoverContent: '',
    })
  } else if (appSupportsMagicAdd && !magicStartIsAvailable) {
    let magicAddTooltip

    if (!hasRemainingMagicAddTries) {
      magicAddTooltip = 'You’ve used all your Generations this month.'
    }
    const disableOption = !hasRemainingMagicAddTries || !magicAddIsAvailable

    const magicAddMenu = (
      <div className="collections-menu-option">
        <Icon type="data" /> <p>Magic Add</p>
      </div>
    )

    const magicAddMenuWrapped = disableOption ? (
      <Tooltip
        className="magic-start-tooltip"
        tooltip={magicAddTooltip}
        placement="right"
      >
        {magicAddMenu}
      </Tooltip>
    ) : (
      magicAddMenu
    )

    options.unshift({
      label: magicAddMenuWrapped,
      onClick: onMagicAddClick,
      locked: disableOption,
      hoverContent: '',
    })
  }

  if (isAdmin) {
    options.push({
      label: (
        <div className="collections-menu-option">
          <Icon type="lock" /> <p>Debug</p>
        </div>
      ),
      onClick: onDatasourceDebugClick,
    })
  }

  return (
    <MultiMenuTrigger menu={options} rowHeight={40} width={180}>
      <IconButton type="more-vert" />
    </MultiMenuTrigger>
  )
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params
  const currentUser = getCurrentUser(state)

  return {
    appId,
    app: getApp(state, appId),
    isAdmin: !!(currentUser && currentUser.admin),
    datasources: getDatasources(state, appId),
    metrics: getMetrics(state, appId),
  }
}

const mapDispatchToProps = {
  createTable,
  loadMetrics,
  removeTable,
  updateTable,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(DataPanel)

export default withRouter(connected)
